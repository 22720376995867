import ElementEditor from "@components/ElementEditor";
import FlowEditor from "@components/FlowEditor";
import Loader from "@components/Loader";
import NavigationArrows from "@components/NavigationArrows";
import Sidebar from "@components/Sidebar";
import TemplateValidationsNotifications from "@components/TemplateValidationsNotifications";
import TopBar from "@components/TopBar";
import { useAnalytics } from "@contexts/Analytics";
import { useAPIContext } from "@contexts/API";
import { useFlowContext } from "@contexts/Flow";
import { useLoader } from "@contexts/Loader";
import { defaultOnboardingOptions, useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingPropsContext } from "@contexts/OnboardingProps";
import CaptureOnboardingStepsContext from "@contexts/OnboardingSteps/CaptureOnboardingStepsContext";
import useAPI, { requestMethods } from "@hooks/useAPI";
import useUpdateEffect from "@hooks/useUpdateEffect";
import Onboarding from "@package/steps";
import { addDefaultPropertiesWhenUserHasNotDone } from "@utils/helpers";
import { prepareOnboardingOptions, unprepareOnboardingOptions } from "@utils/onboarding";
import { onboardingPropsParserTranslator, saveLanguage } from "@utils/translator";
import { Carousel, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.pages.onboarding.builder";

function Builder({ tenantId, templateId }) {
  const { t } = useTranslation();

  const [lastTimeSaved, setLastTimeSaved] = useState(new Date());

  const { flowColors, isFlowBuilder } = useFlowContext();
  const { language } = useAPIContext();
  const { logAnalyticsTracking, analyticsEvents, analytics } = useAnalytics();
  const {
    setOnboardingOptions,
    realOnboardingOptions,
    onboardingOptions,
    onboardingLanguage,
    setTemplateTheme,
    isTemplateDefault,
    setIsTemplateDefault,
  } = useOnboardingOptionsContext();
  const [startLoader, stopLoader] = useLoader();
  const [getTemplateRequest, getTemplateLoading] = useAPI();
  const [updateTemplateRequest, updateTemplateLoading] = useAPI();
  const { activeError, mode, zoom } = useOnboardingPropsContext();
  const { defaultOnboardingValues } = Onboarding;

  useEffect(() => {
    logAnalyticsTracking(analyticsEvents.ACCESS_TEMPLATE);
  }, [analytics]);

  useEffect(async () => {
    const defaultPFTemplate = defaultOnboardingOptions["pt-BR"]?.defaultPFOnboardingOptions;

    if (tenantId && templateId) {
      let res = await getTemplateRequest(`/onboarding-builder/templates/${templateId}?tenantId=${tenantId}`);
      if (res?.template) {
        setOnboardingOptions(unprepareOnboardingOptions(res.template), true, true);
        saveLanguage({ builder: language || "pt-BR", onboarding: res?.template?.internationalization?.language });

        if (res?.theme) {
          setTemplateTheme(res.theme);
          setIsTemplateDefault(typeof res.theme?.isDefault === "undefined" || res.theme?.isDefault === true);
        }
      } else {
        message.error(t("general.label.unableToRetrieveTemplate", "Não foi possível recuperar o template"));
        setOnboardingOptions(unprepareOnboardingOptions(defaultPFTemplate), true, true);
      }
    } else {
      setOnboardingOptions(unprepareOnboardingOptions(defaultPFTemplate), true, true);
    }
  }, [tenantId, templateId]);

  useEffect(() => {
    saveLanguage({
      builder: language || "pt-BR",
      onboarding: realOnboardingOptions?.internationalization?.language,
    });
  }, [language]);

  // Manages the Loader
  useUpdateEffect(() => {
    if (getTemplateLoading) startLoader();
    else if (updateTemplateLoading) startLoader(t("general.label.savingTemplate", "Salvando template..."));
    else stopLoader(500);
  }, [getTemplateLoading, updateTemplateLoading]);

  const onUpdateTemplate = async () => {
    const onboardingStepsTranslated = onboardingOptions?.steps?.map((step) => {
      return onboardingPropsParserTranslator(step, realOnboardingOptions?.internationalization?.language);
    });

    let steps = onboardingStepsTranslated;

    if (isTemplateDefault) {
      steps = addDefaultPropertiesWhenUserHasNotDone({
        steps: onboardingStepsTranslated,
        defaultOnboardingValues,
        language,
      });
    }

    const hasSmartChoice = steps.some((step) => step?.name?.includes("SMART_CHOICE"));

    // function to filter empty objects
    const filterEmptyObjects = (array) => {
      return array?.filter((obj) => Object.keys(obj).length > 0);
    };

    // remove empty objects from options on FLOW_CHOICE | SMART_CHOICE | INSTRUCTIONS steps
    steps = steps?.map((step) => {
      switch (true) {
        case step?.name?.includes("FLOW_CHOICE"):
          const options = step?.options;
          step.options = filterEmptyObjects(options);
          return step;
        case step?.name?.includes("INSTRUCTIONS"):
          step.tips = filterEmptyObjects(step?.tips);
          return step;
        default:
          return step;
      }
    });

    if (hasSmartChoice) {
      steps = steps.filter((step) => step.isEnabled);
    }

    let result = await updateTemplateRequest(
      `/onboarding-builder/templates/${templateId}?tenantId=${tenantId}`,
      requestMethods.PATCH,
      prepareOnboardingOptions({ ...onboardingOptions, steps }, onboardingLanguage),
    );
    if (result) {
      setLastTimeSaved(new Date());
      setOnboardingOptions(onboardingOptions, true, true);
    } else message.error(t(`${I18N_BASE_PATH}.methods.onUpdateTemplate.errorMessage`, "Ocorreu um erro ao salvar"));
  };

  const OnboardingComponent = useMemo(() => {
    if (!language || !realOnboardingOptions?.internationalization?.language) {
      return <Loader isHidden={false} />;
    }

    const onboardingStepsTranslated = onboardingOptions?.steps?.map((step) => {
      return onboardingPropsParserTranslator(step, realOnboardingOptions?.internationalization?.language);
    });

    return (
      <div className="relative h-screen w-screen">
        <Sidebar onUpdateTemplate={templateId && onUpdateTemplate} />
        <div
          className={`absolute inset-0 left-96 transition ${mode} ${
            isFlowBuilder ? "z-0 opacity-0" : "z-10 opacity-100"
          }`}
        >
          <TopBar onUpdateTemplate={templateId && onUpdateTemplate} lastTimeSaved={lastTimeSaved} />
          <TemplateValidationsNotifications />
          <div
            className="max-w-full relative bg-gray-100 top-16"
            style={{ height: "calc(100% - 7rem)" }}
            id="onboarding-container"
          >
            <Onboarding
              language={{
                builder: language || "pt-BR",
                onboarding: realOnboardingOptions?.internationalization?.language,
              }}
              clientOptions={{ ...realOnboardingOptions, steps: onboardingStepsTranslated }}
              flowColors={flowColors}
              activeError={activeError}
              className={`h-full w-full relative`}
              scale={zoom}
              isMobile={false}
            >
              {/* Workaround to make carousel work */}
              <Carousel className="d-none" />

              <ElementEditor />
              <CaptureOnboardingStepsContext />
              <NavigationArrows />
            </Onboarding>
          </div>
        </div>
        <div className={`absolute inset-0 left-96 transition ${isFlowBuilder ? "z-10 opacity-100" : "z-0 opacity-0"}`}>
          <TopBar onUpdateTemplate={templateId && onUpdateTemplate} lastTimeSaved={lastTimeSaved} />
          <TemplateValidationsNotifications />
          <FlowEditor />
        </div>
      </div>
    );
  }, [isFlowBuilder, templateId, zoom, mode, language, realOnboardingOptions, flowColors, activeError]);

  return OnboardingComponent;
}

export default Builder;
